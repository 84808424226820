// HighScoreForm.js

import React, { useState } from 'react';
import axios from 'axios';

const HighScoreForm = ({ score, fetchScores, onSubmitSuccess }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!name) {
      setError('Name is required.');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/submit-score`,
        { name, score },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );
      setSuccess('Score submitted successfully!');
      setName('');
      fetchScores(); // Fetch the updated list of scores
      setTimeout(() => { onSubmitSuccess()}, 1500); // Added setTimeout to delay the close of form
    } catch (error) {
      setError('Failed to submit the score.');
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Submit Your High Score</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
        </div>
        {/* Score input removed */}
        <button type="submit">Submit</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </div>
  );
};

export default HighScoreForm;
