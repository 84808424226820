import React, { useState, useEffect } from 'react';
import { Stage, Layer, Circle, Line, Image as KonvaImage } from 'react-konva';
import HighScoreForm from './HighScoreForm';
import HighScoreTable from './HighScoreTable';
import Modal from './Modal'; 
import axios from 'axios';

// Helper function to get the center position of a line
const getLineCenter = (x1, y1, x2, y2) => ({
  x: (x1 + x2) / 2,
  y: (y1 + y2) / 2,
});

// Function to generate a unique packet ID
const generatePacketId = (() => {
  let id = 0;
  return () => ++id;
})();

const App = () => {
  // State for traffic packets and score
  const [packets, setPackets] = useState([]);
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(false); // Game state (active or not)
  const [packetIntervalId, setPacketIntervalId] = useState(null); // Store interval ID
  const [showHighScoreForm, setShowHighScoreForm] = useState(false); // Track form visibility
  // Get screen dimensions
  const [initialScreenWidth, setInitialScreenWidth] = useState(window.innerWidth);
  const [initialScreenHeight, setInitialScreenHeight] = useState(window.innerHeight);
  const screenWidth = gameActive ? initialScreenWidth : window.innerWidth;
  const screenHeight = gameActive ? initialScreenHeight : window.innerHeight;

  // State for the network switch positions
  const [switch1Position, setSwitch1Position] = useState({ x: screenWidth * 0.1, y: screenHeight * 0.1 });
  const [switch2Position, setSwitch2Position] = useState({ x: screenWidth * 0.3, y: screenHeight * 0.1 });
  const [switch3Position, setSwitch3Position] = useState({ x: screenWidth * 0.2, y: screenHeight * 0.2 });
  const [switch4Position, setSwitch4Position] = useState({ x: screenWidth * 0.2, y: screenHeight * 0.0 });
  const [switch5Position, setSwitch5Position] = useState({ x: screenWidth * 0.35, y: screenHeight * 0.25 });
  const [generatorPosition, setGeneratorPosition] = useState({ x: screenWidth * 0.0, y: screenHeight * 0.0 });
  const [receiverPosition, setReceiverPosition] = useState({ x: screenWidth * 0.35, y: screenHeight * 0.35 });

  // State to hold the image object
  const [switchImage, setSwitchImage] = useState(null);
  const [serverImage, setServerImage] = useState(null);
  const switchHeight = screenHeight * 0.025;
  const switchWidth = switchHeight * 6;
  const serverWidth = screenWidth * 0.05;
  const serverHeight = serverWidth * 2;

  const [scores, setScores] = useState([]);

  // Function to fetch the top scores
  const fetchScores = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/top-scores`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );
      setScores(response.data);
    } catch (error) {
      console.error('Failed to fetch high scores:', error);
    }
  };

  useEffect(() => {
    fetchScores();
  }, []);


  useEffect(() => {
    const loadImage = () => {
      const switchImg = new Image();
      switchImg.src = '/switch.png';
      switchImg.onload = () => setSwitchImage(switchImg);
      const serverImg = new Image();
      serverImg.src = '/server.png';
      serverImg.onload = () => setServerImage(serverImg);
    };
    loadImage();
  }, []);

  // Connection states (true = open, false = blocked)
  const [connections, setConnections] = useState({
    generatorToSwitch1: true,
    switch1ToSwitch2: true,
    switch1ToSwitch3: true,
    switch1ToSwitch4: true,
    switch2ToSwitch3: true,
    switch2ToSwitch4: true,
    switch2ToSwitch5: true,
    switch3ToSwitch5: true,
    switch5ToReceiver: true, // Not eligible to break
  });

  // Track if a link is broken
  const [brokenLinks, setBrokenLinks] = useState({
    generatorToSwitch1: false,
    switch1ToSwitch2: false,
    switch1ToSwitch3: false,
    switch1ToSwitch4: false,
    switch2ToSwitch3: false,
    switch2ToSwitch4: false,
    switch2ToSwitch5: false,
    switch3ToSwitch5: false,
    switch5ToReceiver: false, // Never broken
  });


  // Generate a new packet
  const generatePacket = () => {
    const newPacket = {
      id: generatePacketId(),
      startX: generatorPosition.x + screenWidth * 0.05,
      startY: generatorPosition.y + screenHeight * 0.075,
      endX: switch1Position.x + screenWidth * 0.075,
      endY: switch1Position.y + screenHeight * 0.0125,
      progress: 0,
      from: "generatorToSwitch1",
      visitedLinks: new Set(), // Track links visited by this packet
    };
    setPackets((prev) => [...prev, newPacket]);
  };

  // Start the game
  const startGame = () => {
    setScore(0); // Reset score
    setShowHighScoreForm(false); 
    setGameActive(true); // Mark the game as active
    setInitialScreenWidth(window.innerWidth);  // Set initial screen size
    setInitialScreenHeight(window.innerHeight);

    // Generate packets 3 times per second
    const packetInterval = setInterval(() => {
      generatePacket();
    }, 1000 / 3); // 3 packets per second

    // Save the interval ID so we can clear it later
    setPacketIntervalId(packetInterval);

    // Stop packet generation after 2 minutes (120,000 milliseconds)
    setTimeout(() => {
      clearInterval(packetInterval); // Stop packet generation
      setGameActive(false); // End the game
      setTimeout(() => { setShowHighScoreForm(true)}, 5000); // Show the high score form
    }, 120000); // 2 minutes
  };

  // Stop the game
  const stopGame = () => {
    if (packetIntervalId) {
      clearInterval(packetIntervalId); // Clear the packet interval
    }
    setGameActive(false); // Mark the game as inactive
  };

  // Button to toggle start and stop
  const toggleGame = () => {
    if (gameActive) {
      stopGame(); // Stop the game if it's active
    } else {
      startGame(); // Start the game if it's inactive
    }
  };

  // Close the high score form after submission
  const handleScoreSubmitted = () => {
    setShowHighScoreForm(false);
  };

  // Function to replicate packets when reaching a switch
  const replicatePackets = (packet, currentSwitch) => {
    const newPackets = [];
    const switchConnections = {
      switch1: {
        pos: { x: switch1Position.x + screenWidth * 0.075, y: switch1Position.y + screenHeight * 0.0125 },
        connections: [
          { key: "switch1ToSwitch2", to: switch2Position },
          { key: "switch1ToSwitch3", to: switch3Position },
          { key: "switch1ToSwitch4", to: switch4Position },
          { key: "generatorToSwitch1", to: generatorPosition },
        ],
      },
      switch2: {
        pos: { x: switch2Position.x + screenWidth * 0.075, y: switch2Position.y + screenHeight * 0.0125 },
        connections: [
          { key: "switch1ToSwitch2", to: switch1Position },
          { key: "switch2ToSwitch3", to: switch3Position },
          { key: "switch2ToSwitch4", to: switch4Position },
          { key: "switch2ToSwitch5", to: switch5Position },
        ],
      },
      switch3: {
        pos: { x: switch3Position.x + screenWidth * 0.075, y: switch3Position.y + screenHeight * 0.0125 },
        connections: [
          { key: "switch1ToSwitch3", to: switch1Position },
          { key: "switch2ToSwitch3", to: switch2Position },
          { key: "switch3ToSwitch5", to: switch5Position },
        ],
      },
      switch4: {
        pos: { x: switch4Position.x + screenWidth * 0.075, y: switch4Position.y + screenHeight * 0.0125 },
        connections: [
          { key: "switch1ToSwitch4", to: switch1Position },
          { key: "switch2ToSwitch4", to: switch2Position },
        ],
      },
      switch5: {
        pos: { x: switch5Position.x + screenWidth * 0.075, y: switch5Position.y + screenHeight * 0.0125 },
        connections: [
          { key: "switch2ToSwitch5", to: switch2Position },
          { key: "switch3ToSwitch5", to: switch3Position },
          { key: "switch5ToReceiver", to: receiverPosition },
        ],
      },
    };

    const currentSwitchConnections = switchConnections[currentSwitch];

    currentSwitchConnections.connections.forEach(({ key, to }) => {
      // Replicate onto open connections except the one it came from
      if (packet.from !== key && connections[key] && !brokenLinks[key]) { // Check if link is broken
        const linkKey = key;

        // Check if the link was visited already
        if (packet.visitedLinks.has(linkKey)) {
          setScore((prevScore) => prevScore - 2); // Deduct 2 points if the link is revisited
        } else {
          packet.visitedLinks.add(packet.from); // Add the link to visitedLinks
        }

        newPackets.push({
          id: generatePacketId(),
          startX: currentSwitchConnections.pos.x,
          startY: currentSwitchConnections.pos.y,
          endX: key === 'generatorToSwitch1' ? to.x + screenWidth * 0.05 : (key === 'switch5ToReceiver' ? to.x + screenWidth * 0.05 : to.x + screenWidth * 0.075),
          endY: key === 'generatorToSwitch1' ? to.y + screenHeight * 0.075 : (key === 'switch5ToReceiver' ? to.y + screenHeight * 0.075 : to.y + screenHeight * 0.0125), 
          progress: 0,
          from: key,
          visitedLinks: new Set(packet.visitedLinks), // Copy the visited links
        });
      }
    });

    return newPackets;
  };

  // Traffic movement mechanism
  useEffect(() => {
    const movePackets = () => {
      setPackets((prevPackets) => {
        const updatedPackets = prevPackets
          .flatMap((packet) => {
            const step = 0.01; // Packet speed
            const newProgress = packet.progress + step;

            if (newProgress >= 1) {
              // If packet reaches the receiver, score 1 point and destroy it
              if (packet.endX === receiverPosition.x + screenWidth * 0.05 && packet.endY === receiverPosition.y + screenHeight * 0.075) {
                setScore((prevScore) => prevScore + 1); // Add 1 point when reaching the receiver
                return []; // Destroy packet
              }

              // Replicate at the switch, and destroy the original packet
              let replicatedPackets = [];

              if (packet.endX === switch1Position.x + screenWidth * 0.075 && packet.endY === switch1Position.y + screenHeight * 0.0125) {
                replicatedPackets = replicatePackets(packet, "switch1");
              } else if (packet.endX === switch2Position.x + screenWidth * 0.075 && packet.endY === switch2Position.y + screenHeight * 0.0125) {
                replicatedPackets = replicatePackets(packet, "switch2");
              } else if (packet.endX === switch3Position.x + screenWidth * 0.075 && packet.endY === switch3Position.y + screenHeight * 0.0125) {
                replicatedPackets = replicatePackets(packet, "switch3");
              } else if (packet.endX === switch4Position.x + screenWidth * 0.075 && packet.endY === switch4Position.y + screenHeight * 0.0125) {
                replicatedPackets = replicatePackets(packet, "switch4");
              } else if (packet.endX === switch5Position.x + screenWidth * 0.075 && packet.endY === switch5Position.y + screenHeight * 0.0125) {
                replicatedPackets = replicatePackets(packet, "switch5");
              }


              // Return only the replicated packets (destroy the original)
              return replicatedPackets;
            } else {
              // Continue moving the packet
              return {
                ...packet,
                progress: newProgress,
              };
            }
          });

        return updatedPackets;
      });
    };

    const interval = setInterval(movePackets, 8); // ~60 FPS
    return () => clearInterval(interval);
  }, [connections, brokenLinks, packets, switch1Position, switch2Position, switch3Position, generatorPosition, receiverPosition]);

// Function to randomly break a link and automatically heal it after 2-6 seconds
const breakAndHealLink = () => {
  const linkKeys = Object.keys(brokenLinks).filter(key => key !== 'switch5ToReceiver');
  const randomLink = linkKeys[Math.floor(Math.random() * linkKeys.length)];

  // Break the selected link
  setBrokenLinks((prevState) => ({
    ...prevState,
    [randomLink]: true,
  }));

  // Automatically heal the link after 2 to 6 seconds
  const healTime = Math.random() * (6000 - 2000) + 2000; // 2 to 6 seconds
  setTimeout(() => {
    setBrokenLinks((prevState) => ({
      ...prevState,
      [randomLink]: false,
    }));
  }, healTime);
};

// Set interval for random link breakage and healing
useEffect(() => {
  let interval;
  if (gameActive){
    interval = setInterval(breakAndHealLink, Math.random() * (6000 - 2000) + 2000); // 2 to 6 seconds
  }

  return () => clearInterval(interval);
}, [gameActive, brokenLinks]);


  // Toggle connection state between open and blocked (user control)
  const toggleConnection = (connectionKey) => {
    setConnections((prevState) => ({
      ...prevState,
      [connectionKey]: !prevState[connectionKey],
    }));
  };

  return (
    <div>
      <h1>G.8032 Network Switch Game</h1>
      <h1>A work in progress by: Ben Jacobson</h1>
      <h2>drag the nodes around to better view the connections</h2>
      <p>The circles on the links connecting the switches represet RPLs and may be toggled between a blocking (red) and forwarding (black) state by clicking the circle.</p>
      <p>Links will randomly become broken (red) and then some seconds later automatically become healed (black)</p>
      <p>After clicking the 'Start' button, broadcast packets will begin to generate from the Generator server.</p>
      <p>Points are scored by successfully switching a packet from the generator to the Receiver.</p>
      <p>However, points are detracted for each time a packet loops around the network.</p>
      <p>The switches will replicate the broadcast packets using the same logic as real life, so a broadcast storm can get out of hand very quickly, you've been warned.</p>
      <p>Packets will continue to forward for 2 minutes before your score is logged. High score board is at the bottom!</p>
      <button onClick={toggleGame}>{gameActive ? 'Stop Game' : 'Start Game'}</button>
      <h2>Score: {score}</h2>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        style={{ border: '1px solid grey' }}
      >
        <Layer>
          {/* Render traffic packets */}
          {packets.map((packet) => (
            <Circle
              key={packet.id}
              x={packet.startX + packet.progress * (packet.endX - packet.startX)}
              y={packet.startY + packet.progress * (packet.endY - packet.startY)}
              radius={10}
              fill="red"
            />
          ))}
          {/* Line connecting the Generator and switch1 */}
          <Line
            points={[generatorPosition.x + screenWidth * 0.05, generatorPosition.y + screenHeight * 0.075, switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125]}
            stroke={'black'}
            strokeWidth={2}
          />
          {/* Line connecting switch1 and switch2 */}
          <Line
            points={[switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125, switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch1ToSwitch2 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125, switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch1ToSwitch2 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch1ToSwitch2')}
            onTap={() => toggleConnection('switch1ToSwitch2')}
          />

          {/* Line connecting switch1 and switch3 */}
          <Line
            points={[switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125, switch3Position.x + screenWidth * 0.075, switch3Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch1ToSwitch3 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125, switch3Position.x + screenWidth * 0.075, switch3Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch1ToSwitch3 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch1ToSwitch3')}
            onTap={() => toggleConnection('switch1ToSwitch3')}
          />

          {/* Line connecting switch2 and switch3 */}
          <Line
            points={[switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125, switch3Position.x + screenWidth * 0.075, switch3Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch2ToSwitch3 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125, switch3Position.x + screenWidth * 0.075, switch3Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch2ToSwitch3 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch2ToSwitch3')}
            onTap={() => toggleConnection('switch2ToSwitch3')}
          />

          {/* Line connecting switch1 and switch4 */}
          <Line
            points={[switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125, switch4Position.x + screenWidth * 0.075, switch4Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch1ToSwitch4 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch1Position.x + screenWidth * 0.075, switch1Position.y + screenHeight * 0.0125, switch4Position.x + screenWidth * 0.075, switch4Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch1ToSwitch4 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch1ToSwitch4')}
            onTap={() => toggleConnection('switch1ToSwitch4')}
          />

          {/* Line connecting switch2 and switch4 */}
          <Line
            points={[switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125, switch4Position.x + screenWidth * 0.075, switch4Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch2ToSwitch4 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125, switch4Position.x + screenWidth * 0.075, switch4Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch2ToSwitch4 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch2ToSwitch4')}
            onTap={() => toggleConnection('switch2ToSwitch4')}
          />

          {/* Line connecting switch2 and switch5 */}
          <Line
            points={[switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125, switch5Position.x + screenWidth * 0.075, switch5Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch2ToSwitch5 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch2Position.x + screenWidth * 0.075, switch2Position.y + screenHeight * 0.0125, switch5Position.x + screenWidth * 0.075, switch5Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch2ToSwitch5 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch2ToSwitch5')}
            onTap={() => toggleConnection('switch2ToSwitch5')}
          />

          {/* Line connecting switch3 and switch5 */}
          <Line
            points={[switch3Position.x + screenWidth * 0.075, switch3Position.y + screenHeight * 0.0125, switch5Position.x + screenWidth * 0.075, switch5Position.y + screenHeight * 0.0125]}
            stroke={brokenLinks.switch3ToSwitch5 ? 'red' : 'black'}
            strokeWidth={2}
          />
          <Circle
            {...getLineCenter(switch3Position.x + screenWidth * 0.075, switch3Position.y + screenHeight * 0.0125, switch5Position.x + screenWidth * 0.075, switch5Position.y + screenHeight * 0.0125)}
            radius={15}
            fill={connections.switch3ToSwitch5 ? 'black' : 'red'}
            onClick={() => toggleConnection('switch3ToSwitch5')}
            onTap={() => toggleConnection('switch3ToSwitch5')}
          />

          {/* Line connecting switch5 and receiver */}
          <Line
            points={[switch5Position.x + screenWidth * 0.075, switch5Position.y + screenHeight * 0.0125, receiverPosition.x + screenWidth * 0.05, receiverPosition.y + screenHeight * 0.075]}
            stroke={'black'}
            strokeWidth={2}
          />

          {/* Draggable host (generator) represented as a circle */}
          <KonvaImage
            x={generatorPosition.x}
            y={generatorPosition.y}
            image={serverImage}
            width={serverWidth}
            height={serverHeight}
            draggable={!gameActive}
            onDragMove={(e) => {
              setGeneratorPosition({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />

          {/* Draggable receiver (host) represented as a circle */}
          <KonvaImage
            x={receiverPosition.x}
            y={receiverPosition.y}
            image={serverImage}
            width={serverWidth}
            height={serverHeight}
            draggable={!gameActive}
            onDragMove={(e) => {
              setReceiverPosition({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />

          {/* Draggable network switch represented as a KonvaImage */}
          <KonvaImage
            x={switch1Position.x}
            y={switch1Position.y}
            width={switchWidth}
            height={switchHeight}
            image={switchImage}
            draggable={!gameActive}
            onDragMove={(e) => {
              setSwitch1Position({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />
          <KonvaImage
            x={switch2Position.x}
            y={switch2Position.y}
            width={switchWidth}
            height={switchHeight}
            image={switchImage}
            draggable={!gameActive}
            onDragMove={(e) => {
              setSwitch2Position({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />
          <KonvaImage
            x={switch3Position.x}
            y={switch3Position.y}
            width={switchWidth}
            height={switchHeight}
            image={switchImage}
            draggable={!gameActive}
            onDragMove={(e) => {
              setSwitch3Position({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />
          <KonvaImage
            x={switch4Position.x}
            y={switch4Position.y}
            width={switchWidth}
            height={switchHeight}
            image={switchImage}
            draggable={!gameActive}
            onDragMove={(e) => {
              setSwitch4Position({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />
          <KonvaImage
            x={switch5Position.x}
            y={switch5Position.y}
            width={switchWidth}
            height={switchHeight}
            image={switchImage}
            draggable={!gameActive}
            onDragMove={(e) => {
              setSwitch5Position({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
          />
        </Layer>
      </Stage>
      <h1>G.8032 Network Switch Game High Scores</h1>
      <HighScoreTable scores={scores} />
      <Modal show={showHighScoreForm}>
        <HighScoreForm score={score} fetchScores={fetchScores} onSubmitSuccess={handleScoreSubmitted} />
      </Modal>
    </div>
  );
};

export default App;
